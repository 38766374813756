'use client'

import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import Button from '@/components/ViverseButton'
import RoomsCarousel from '@/components//RoomsCarousel'
import { EditorChoice } from '@/services/cms'
import StackCardsContainer from '@/components/StackCardsContainer'
import ActionWorldCard from '@/components/ActionWorldCard'
import { useWindowSize } from 'usehooks-ts'

type CuratedWorldsProps = {
  editorChoices: EditorChoice[]
}

function CuratedWorlds({ editorChoices }: CuratedWorldsProps) {
  const t = useTranslations()
  const router = useRouter()
  const { width: windowWidth } = useWindowSize()

  const handleGoToUserWorlds = () => {
    router.push('/user-worlds')
  }

  if (editorChoices?.length < 1) return null

  return (
    <section className="mt-20 flex flex-col 1.5xl:mt-[120px] 1.5xl:items-center">
      {editorChoices?.map(({ id, title, rooms }, index) => (
        <div key={`curated-worlds-editor-choice-${id}-${index}`}>
          {windowWidth > 1024 ? (
            <RoomsCarousel title={title} rooms={rooms} hideLiked />
          ) : (
            <StackCardsContainer
              titleClassName="subheader-2 mb-3 text-vive-primary-20 lg:header-4 lg:mb-6 lg:text-vive-primary-20"
              title={title}
              className="mb-[30px]"
            >
              {rooms.map((room, index) => (
                <ActionWorldCard
                  className="w-[248px] min-w-[248px] lg:w-auto"
                  key={`followings-card-${room.id}-${index}`}
                  info={room}
                />
              ))}
            </StackCardsContainer>
          )}
        </div>
      ))}
      <div className="mt-[6px] text-center 1.5xl:mt-3">
        <Button size="large" variant="secondary" onClick={handleGoToUserWorlds}>
          {t('landing-page.curated-worlds-cta')}
        </Button>
      </div>
    </section>
  )
}

export default CuratedWorlds
