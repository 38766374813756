type CarouselTitleProps = {
  title?: string
}

function CarouselTitle({ title }: CarouselTitleProps) {
  if (!title) return null

  return (
    <div className="mx-6 mb-3 flex cursor-default items-center gap-2 md:mx-12 lg:mx-[70px] lg:mb-6 1.5xl:mx-0 1.5xl:ml-5">
      <h2 className="lg:header-4 subheader-1 !text-vive-primary-20">{title}</h2>
    </div>
  )
}

export default CarouselTitle
