'use client'

import { useTranslations } from 'next-intl'

import HeroSection, { HeroSectionConfig } from '@/components/HeroSection'
import IconOrganization from '@/components/assets/icon_organization.svg'
import IconFriend from '@/components/assets/icon_friend.svg'
import IconGift from '@/components/assets/icon_gift.svg'
import becomeACreatorMobile from '@/public/images/landing-heros/become-a-creator-2@mobile.jpg'
import becomeACreatorPc from '@/public/images/landing-heros/become-a-creator-2@pc.jpg'

function BecomeCreatorHeroSection() {
  const t = useTranslations()

  const props: HeroSectionConfig = {
    banner: {
      title: t('landing-page.become-a-creator-title'),
      description: t('landing-page.become-a-creator-description'),
      className: 'object-[70%]',
      images: {
        mobile: becomeACreatorMobile.src,
        pc: becomeACreatorPc.src,
      },
      // action: (
      //   <Link target="_blank" href={env('NEXT_PUBLIC_CREATOR_FORM_URL')!}>
      //     <ViverseButton size="extra" variant="extra">
      //       {t('landing-page.become-a-creator-cta')}
      //     </ViverseButton>
      //   </Link>
      // ),
    },
    infos: [
      {
        backgroundColor: 'blue',
        icon: <IconOrganization />,
        title: t('landing-page.become-a-creator-info-1-title'),
        description: t('landing-page.become-a-creator-info-1-description'),
      },
      {
        backgroundColor: 'purple',
        icon: <IconFriend />,
        title: t('landing-page.become-a-creator-info-2-title'),
        description: t('landing-page.become-a-creator-info-2-description'),
      },
      {
        backgroundColor: 'green',
        icon: <IconGift />,
        title: t('landing-page.become-a-creator-info-3-title'),
        description: t('landing-page.become-a-creator-info-3-description'),
      },
    ],
  }

  return <HeroSection className="mt-[120px] 1.5xl:mt-[200px]" variant="left" {...props} />
}

export default BecomeCreatorHeroSection
