import { HTMLAttributes } from 'react'
import { cn } from '@/lib/utils'

type RollingCarouselProps = HTMLAttributes<HTMLDivElement> & {
  direction: 'left' | 'right'
}

const rollingCarouselClassName = {
  left: 'animate-scroll-left',
  right: 'animate-scroll-right',
}

function RollingCarousel({ direction, children, ...props }: RollingCarouselProps) {
  return (
    <div className="h-auto w-full max-w-screen-4xl overflow-hidden" {...props}>
      <div className="relative flex w-fit">
        <div
          className={cn(
            'relative flex hover:[animation-play-state:paused]',
            rollingCarouselClassName[direction],
          )}
        >
          <div className="1.5xl:mt-[36px] mt-[16px] flex gap-3 lg:gap-5">
            {children}
            {/* Forever rolling need supplementary element */}
            {children}
          </div>
        </div>
        <GradientMask />
      </div>
    </div>
  )
}

function GradientMask() {
  return (
    <div className="pointer-events-none absolute h-full w-dvw max-w-screen-4xl">
      <div className="absolute left-0 h-full w-48 bg-gradient-to-r from-background"></div>
      <div className="absolute right-0 h-full w-48 bg-gradient-to-l from-background"></div>
    </div>
  )
}

export default RollingCarousel
