'use client'

import { useEffect, useRef } from 'react'
import Image from 'next/image'

import { Card } from '@/components/ui/card'
import { CarouselItem } from '@/components/ui/carousel'
import TiltCard from '@/components/TiltCard'
import BannerItemContent from '@/app/BannerItemContent'
import { ActivityShowcaseList } from '@/services/cms'
import { cn } from '@/lib/utils'

type BannerItemProps = {
  item: ActivityShowcaseList
  index: number
  current: number
  handleNext: () => void
}

function BannerItem({ item, index, current, handleNext }: BannerItemProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const shouldAutoPlay = !item.video && current === index

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0

      if (current === index) {
        videoRef.current?.play()
      }
    }
  }, [current, index])

  useEffect(() => {
    if (!shouldAutoPlay) return

    const timer = setTimeout(handleNext, 5000)

    return () => clearTimeout(timer)
  }, [shouldAutoPlay, handleNext])

  function handleVideoEnd() {
    if (current !== index) return
    handleNext()
  }

  return (
    <CarouselItem key={item.title} className="relative rounded-none bg-cover bg-center">
      {item.video ? (
        <video
          className="absolute inset-0 size-full object-cover"
          muted
          playsInline
          onEnded={handleVideoEnd}
          ref={videoRef}
        >
          {item.video.webm && <source src={item.video.webm} type="video/webm" />}
          {item.video.mp4 && <source src={item.video.mp4} type="video/mp4" />}
        </video>
      ) : (
        <Image className="size-full object-cover" src={item.background!} alt={item.title} fill />
      )}

      <Card
        className={cn(
          'm-auto flex size-full max-w-[1340px] flex-col justify-end gap-9 rounded-none border-0 bg-transparent md:gap-8 lg:flex-row lg:justify-center lg:gap-0',
          {
            'lg:pr-[294px] 1.5xl:pr-[445px]': !item.card,
          },
        )}
      >
        {item.card && <TiltCard backgroundUrl={item.card} />}
        <BannerItemContent isActive={current === index} list={item} />
      </Card>
      <div
        id="carousel-card-shadow"
        className="absolute bottom-0 left-0 z-0 h-20 w-full bg-mkt-banner-shadow md:h-60 lg:h-[278px]"
      />
    </CarouselItem>
  )
}

export default BannerItem
