'use client'

import ActionWorldCard from '@/components/ActionWorldCard'
import { Room } from '@/services/cms'
import CarouselSlider from '@/components/CarouselSlider'
import CarouselTitle from '@/components/CarouselTitle'

type RoomsCarouselProps = {
  title?: string
  rooms: Room[]
  hideLiked?: boolean
}

function RoomsCarousel({ title, rooms, hideLiked = false }: RoomsCarouselProps) {
  return (
    <div className="mb-[30px] 1.5xl:mx-auto 1.5xl:mb-12">
      <CarouselTitle title={title} />
      <CarouselSlider
        count={rooms.length}
        countOfRow={4}
        controllerClassName="lg:mt-10 1.5xl:mt-[70px] 3xl:mt-[104px] 4xl:mt-[148px] lg:items-start"
      >
        {rooms.map((room, index) => (
          <div
            key={`world-card-${room.id}-${index}`}
            className="lg:w-[calc(206px_+_(100vw_-_1024px)_/_4)] 4xl:w-[590px]"
          >
            <ActionWorldCard info={room} hideLiked={hideLiked} />
          </div>
        ))}
      </CarouselSlider>
    </div>
  )
}

export default RoomsCarousel
