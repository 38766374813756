'use client'

import { useState, useEffect, memo } from 'react'
import { Carousel, CarouselContent, type CarouselApi } from '@/components/ui/carousel'
import ArrowButton from '@/components/ArrowButton'
import { ActivityShowcaseList } from '@/services/cms'
import { cn } from '@/lib/utils'
import BannerItem from '@/app/BannerItem'

type HeroBannerProps = {
  lists: ActivityShowcaseList[]
}

const HeroBanner = memo(function HeroBanner({ lists }: HeroBannerProps) {
  const [api, setApi] = useState<CarouselApi>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [count, setCount] = useState(lists.length ?? 0)

  useEffect(() => {
    if (!api) return

    setCount(api.scrollSnapList().length)
    setCurrentIndex(api.selectedScrollSnap())

    api.on('select', () => {
      setCurrentIndex(api.selectedScrollSnap())
    })
  }, [api, currentIndex, lists])

  const handleNext = () => {
    if (!api) return
    api.scrollNext()
  }

  const handlePrev = () => {
    if (!api) return
    api.scrollPrev()
  }

  const handleGoTo = (index: number) => {
    if (!api) return
    api.scrollTo(index)
  }

  return (
    <Carousel
      className="group relative overflow-hidden"
      opts={{ align: 'start', loop: true }}
      setApi={setApi}
    >
      <CarouselContent className="h-dvh">
        {lists.map((item, index) => (
          <BannerItem
            key={item.title}
            item={item}
            index={index}
            current={currentIndex}
            handleNext={handleNext}
          />
        ))}
      </CarouselContent>

      <div className="absolute bottom-0 flex h-[38px] w-full items-center justify-center gap-3 text-center text-sm text-white">
        {Array.from({ length: count }).map((_, index) => (
          <span
            key={`carousel-order-dots-${index}`}
            className={cn('h-[10px] cursor-pointer transition-all duration-700 ease-in-out', {
              'w-10 rounded-lg bg-vive-brand-50': currentIndex === index,
              'aspect-square rounded-full bg-vive-primary-70 hover:bg-vive-primary-50':
                currentIndex !== index,
            })}
            onClick={() => handleGoTo(index)}
          />
        ))}
      </div>
      {count > 1 && (
        <div className="pointer-events-none absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 justify-between opacity-0 transition-opacity duration-200 lg:w-[924px] lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 1.5xl:w-[1340px]">
          <ArrowButton direction="left" onClick={handlePrev} />
          <ArrowButton direction="right" onClick={handleNext} />
        </div>
      )}
    </Carousel>
  )
})

export default HeroBanner
