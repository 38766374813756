'use client'

import { MouseEventHandler } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import ViverseCard, { ViverseCardProps } from '@/components/ViverseCard'
import IconLikeBordered from '@/components/assets/icon_like_bordered.svg'
import IconLikeFilled from '@/components/assets/icon_like_filled.svg'
import { Owner } from '@/services/cms'
import { cn } from '@/lib/utils'
import roomDefaultImage from '@/public/images/room-default.png'
import defaultAvatarImage from '@/public/images/creator-intro/avatar-2.png'

export interface LikedWorldCardProps extends ViverseCardProps {
  title: string
  image: string
  owner: Owner
  isLiked?: boolean
  href: string
  isLive?: boolean
  liveCount?: number
  onClickLike?: MouseEventHandler
  hideLiked?: boolean
}

const ownerPlaceholder = {
  id: '',
  display_name: '',
  snapshot_url: defaultAvatarImage.src,
  head_icon_url: defaultAvatarImage.src,
  htc_account_id: '',
}

function LikedWorldCard({
  className,
  title = 'titletitletitletitletitletitletitletitle',
  image,
  owner = ownerPlaceholder,
  isLiked = false,
  isLive = false,
  liveCount = 0,
  // eslint-disable-next-line no-console
  onClickLike = () => console.log('[LikedWorldCard]: called onClickLike'),
  hideLiked = false,
  href,
  ...props
}: LikedWorldCardProps) {
  return (
    <ViverseCard className={cn('group', className)} {...props}>
      <ViverseCard.Content className="flex items-center align-middle">
        {!hideLiked && (
          <div
            className="absolute right-3 top-[10px] z-10 size-6 opacity-0 transition-opacity duration-150 group-hover:opacity-100 1.5xl:size-7"
            onClick={onClickLike}
          >
            {isLiked ? <IconLikeFilled /> : <IconLikeBordered />}
          </div>
        )}
        <Link href={href} className="block size-full cursor-pointer">
          <div className="relative aspect-[1.72]">
            <Image
              src={image || roomDefaultImage}
              className="transition-transform duration-300 hover:scale-110"
              fill
              alt={title}
              style={{ objectFit: 'cover' }}
            />
          </div>
        </Link>
        <div className="absolute bottom-0 left-0 flex w-full items-center bg-[#00000099] px-3 py-[5px] opacity-0 backdrop-blur transition-opacity duration-150 group-hover:opacity-100">
          <Link href={`/profile/${owner.htc_account_id}`} className="cursor-pointer">
            <div className="relative size-[30px] rounded-full 1.5xl:size-12">
              <Image alt={owner.display_name} src={owner.head_icon_url} fill />
            </div>
          </Link>
          <div className="ml-3 max-w-44 1.5xl:max-w-60">
            <Link href={href} className="cursor-pointer">
              <p className="subheader-3 1.5xl:subheader-1 truncate">{title}</p>
            </Link>
            <Link href={`/profile/${owner.htc_account_id}`} className="cursor-pointer">
              <p className="caption truncate text-left">{owner.display_name}</p>
            </Link>
          </div>
        </div>
        {isLive && (
          <div className="absolute left-[12px] top-[12px] flex items-center gap-[6px] rounded-full bg-[#00000099] px-[8px] py-[3px]">
            <div className="size-2 rounded-full bg-[#EC4141]" />
            <div className="text-[12px] font-medium tracking-[0.19px] text-vive-white">
              {`Live ${liveCount ? `- ${liveCount} online` : ''}`}
            </div>
          </div>
        )}
      </ViverseCard.Content>
    </ViverseCard>
  )
}

export default LikedWorldCard
