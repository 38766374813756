'use client'

import { useRouter } from 'next/navigation'
import { CardContent } from '@/components/ui/card'
import ViverseButton from '@/components/ViverseButton'
import { ActivityShowcaseList } from '@/services/cms'
import { useTranslations } from 'next-intl'

type BannerItemContentProps = {
  list: ActivityShowcaseList
  isActive: boolean
}

function BannerItemContent({ isActive, list }: BannerItemContentProps) {
  const t = useTranslations()
  const router = useRouter()
  const {
    title,
    video,
    description,
    view_count,
    like_count,
    online_counts,
    action_link,
    action_title,
  } = list

  const VIEWS_INFO = [
    { carouselCount: online_counts, label: 'online' },
    { carouselCount: view_count, label: 'views' },
    { carouselCount: like_count, label: 'likes' },
  ]

  const handleActionLinkClick = (actionLink: string) => {
    const currentDomain = window.location.origin
    if (actionLink.startsWith(currentDomain)) {
      router.push(actionLink)
    } else {
      window.open(actionLink, '_blank')
    }
  }

  return (
    <CardContent className="z-20 flex min-h-[295px] flex-col px-6 py-0 md:min-h-[312px] lg:mx-0 lg:w-[454px] lg:justify-center lg:px-0 lg:pt-20 1.5xl:w-[530px]">
      <div className="mb-2 overflow-hidden lg:mb-2.5">
        <p
          className={`header-2 line-clamp-2 break-words transition-all duration-700 ease-in lg:text-[64px] lg:font-extrabold ${isActive ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} `}
        >
          {title}
        </p>
      </div>

      <div className="overflow-hidden">
        <div
          className={`${isActive ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'} transition-all duration-700 ease-in`}
        >
          <span className="subheader-1 lg:header-4 mb-3 line-clamp-3 break-words lg:mb-5 lg:text-vive-white">
            {description}
          </span>
          <div className="mb-3 flex gap-4 lg:mb-5">
            {VIEWS_INFO.map(
              (info, index) =>
                !!info.carouselCount && (
                  <div key={index} className="flex items-end gap-2">
                    <p className="header-4 md:header-3">{info.carouselCount}</p>
                    <span className="body-1 lg:subheader-1 pb-[2px] text-vive-white">
                      {info.label}
                    </span>
                  </div>
                ),
            )}
          </div>
          {action_link && (
            <ViverseButton
              size="extra"
              variant="extra"
              onClick={() => handleActionLinkClick(action_link)}
            >
              {action_title}
            </ViverseButton>
          )}
          {video && (
            <div className="body-1 lg:subheader-1 mt-6 pb-[2px] text-white lg:mt-4">
              {t('homepage.banner.cta_tips')}
            </div>
          )}
        </div>
      </div>
    </CardContent>
  )
}

export default BannerItemContent
